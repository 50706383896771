import { useEffect, useLayoutEffect, useState } from 'react';
import { useRouter } from 'next/router';
import {
  CurrentUser,
  DealTypeForTab,
  GearResponse,
  HomePageData,
  MenuItem,
  MenuResponse,
  OgData,
  SimpleItem,
} from 'types';
import { trackSessionRedirects } from 'utils/localStoreTools';
import { getHomePageLdJson } from 'utils/seoFormatters';
import { CustomerPageWrapper } from 'containers/CustomerPageWrapper';

import { GearBlock } from '../Gear';

export type HomeProps = {
  activities: Array<MenuItem & SimpleItem>;
  initialGear: GearResponse;
  menuData: MenuResponse;
  ogData?: OgData[];
  pageData: HomePageData;
  tab?: DealTypeForTab;
  user?: CurrentUser;
};

export const Home = ({
  activities,
  initialGear,
  menuData = {} as MenuResponse,
  appliedFilters,
  pageData,
  user,
  ogData,
  tab = 'all',
}: HomeProps) => {
  const router = useRouter();
  const { badge_section: badgeSection } = pageData;
  const [activeTab, setActiveTab] = useState<DealTypeForTab>(tab);
  const ldJsonData = getHomePageLdJson();
  const seoHiddenProps = {
    title: 'SENDY: BUY - SELL - RENT - SEND',
    description:
      'SENDY is an online marketplace and gear hub that enables community members to Buy, sell, and Rent Adventure Sports equipment anywhere in North America. Brought to you by Adventure Sports icons Travis Rice and Cam Zink, the founding team of SENDY brings a deep understanding of the action sports community. Join us today and explore a marketplace like no other.',
    image: '/sendy_default_banner.jpg',
  };

  useLayoutEffect(() => {
    trackSessionRedirects(router);
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const filters = activeTab !== 'all' ? { type: activeTab } : ({} as Record<string, DealTypeForTab>);

  return (
    <CustomerPageWrapper
      badge={badgeSection}
      filters={filters}
      headTitle="Home | SENDY"
      hiddenComponentProps={seoHiddenProps}
      ldJsonData={ldJsonData}
      menuData={menuData}
      ogData={ogData}
      user={user}
    >
      <>
        <GearBlock initialGear={initialGear} activities={activities} filters={undefined} appliedFilters={appliedFilters} user={user} isHome />
      </>
    </CustomerPageWrapper>
  );
};
